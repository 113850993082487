export interface CookieAttributes {
  value: string;
  path?: string;
  expires?: string;
  maxAge?: number;
  domain?: string;
  secure?: boolean;
  httpOnly?: boolean;
  sameSite?: 'Strict' | 'Lax' | 'None';
}

export type Cookies = Map<string, CookieAttributes>

export function parseCookies(cookieHeader: string): Cookies {
  return cookieHeader.split(';').reduce((cookiesMap, cookie) => {
    const [name, ...rest] = cookie.trim().split('=')
    const value = rest.join('=')  // Соединяем оставшуюся часть, если значение содержит "="
    cookiesMap.set(name, {value: decodeURIComponent(value)})  // Добавляем в Map декодированное значение
    return cookiesMap
  }, new Map<string, CookieAttributes>())
}

export const deleteCookieSession = () => {
  document.cookie = 'session' + '=; Max-Age=0; path=/'
}

export function clearSessionCookie(cookies: Map<string, any>) {
  cookies.set("session", {
    value: "not-valid",
    path: "/",
    expires: "Thu, 01 Jan 1970 00:00:00 GMT",
  })
}