import {userDataFromJWT} from "./atoms/secure/jwt/app.js"
import {types} from "mobx-state-tree"
import {quantum} from "https://sustechpoints.test.adas.e-kama.com/core/quantum/index.js"
import {autorun} from "mobx"
import {modelSecureState} from "./atoms/secure/secure.model.js"
import atoms from "./atoms"
import {deleteCookieSession} from "../core/utils/cookie.js"
import {metaDataModel} from "https://sustechpoints.test.adas.e-kama.com/core/meta/index.js"
import "https://sustechpoints.test.adas.e-kama.com/core/web/browser.js"

localStorage.getItem('debug') === 'true' && import("../core/debug/debug.js")
atoms.get('atom-editor').attach()

const model = types.model('main', {
  context: types.model({
    user: types.maybeNull(modelSecureState),
  }),
  state: types.array(types.model({
    user: types.number,
    device: types.number,
    tab: types.number,
    name: types.string,
  }))
}).actions(self => ({
  setUser: (snapshot) => self.context.user = snapshot,
}))

const main = model.actions(quantum(metaDataModel(model), [
  { // Получение пользователя после авторизации
    name: "atom-secure",
    path: "/state",
    apply: ({patch: {value}, self}) => self.setUser(value)
  },
  {
    name: "atom-profile",
    path: "/state",
    value: null,
    apply: () => {
      deleteCookieSession()
      window.location.reload()
    }
  },
  { // Окно - открыть
    name: "atom-dock",
    path: "/state/*",
    value: "loading",
    apply: ({patch}) => atoms.get(patch.path.split('/')[2]).attach()
  },
  { // Окно - закрыть для всех атомов у кого имеется view
    path: "/view/window/status",
    value: "close",
    apply: ({meta}) => atoms.get(meta.name)?.destroy()
  },
])).create({
  context: {
    user: userDataFromJWT(),
  }
})

autorun(() => {
  switch (true) {
    case main.context.user?.role === "USER":
    case main.context.user?.role === "SUPERUSER":
      atoms.get("atom-secure").destroy()
      atoms.get("atom-dock").attach()
      // atoms.get("atom-datasets").attach()
      // atoms.get("atom-profile").attach()
      break
    case !main.context.user:
      atoms.get("atom-secure").attach()
      break
  }
})