import {createAtomsMap} from "https://sustechpoints.test.adas.e-kama.com/core/quantum/index.js"

export default createAtomsMap([
  {
    name: "atom-users",
    loadFunction: () => import('./users/users.atom.js')
  },
  {
    name: "atom-roles",
    loadFunction: () => import('./roles/roles.atom.js')
  },
  {
    name: "atom-datasets",
    loadFunction: () => import('./datasets/datasets.atom.js'),
  },
  {
    name: "atom-dock",
    loadFunction: () => import('./dock/dock.atom.js')
  },
  {
    name: "atom-secure",
    loadFunction: () => import('./secure/secure.atom.js')
  },
  {
    name: "atom-profile",
    loadFunction: () => import('./profile/profile.atom.js')
  },
  {
    name: "atom-editor",
    loadFunction: () => import('./editor/editor.atom.js'),
    params: {id: "main-editor"}
  }
])
